<template>
	<div v-show="getType" class="agent" :style="{ minHeight: minHeight+'px' }">
		<div :class="{ 'kol-bg': type != '1' }" class="header qjc-relative">
			<span>疫境求真</span>
			<div v-if="type == 2 || type == 3" class="oper qjc-absolute">
				<img @click="$router.push('/agent/changePwd')" class="edit" src="../../../assets/images/agent_index_edit.png" alt="修改密码">
				<img @click="exit" class="logout" src="../../../assets/images/agent_index_exit.png" alt="退出">
			</div>
			<span v-else @click="exit" class="exit qjc-absolute">退出</span>
		</div>
		
		<!-- 导航 -->
		<!-- 代理人 -->
		<div v-if="type == '1'" class="nav">
			<dl @click="$router.push('/agent/packageBuy')">
				<dt>
					<img src="../../../assets/images/agent_combo_buy.png" alt="套餐購買">
				</dt>
				<dd>套餐購買</dd>
			</dl>
			<!-- <dl @click="$router.push('/agent/packageOpen')">
				<dt>
					<img src="../../../assets/images/agent_combo_open.png" alt="套餐購買">
				</dt>
				<dd>套餐開通</dd>
			</dl> -->
			<dl @click="$router.push('/agent/earnings')">
				<dt>
					<img src="../../../assets/images/agent_index_earnings.png" alt="收益">
				</dt>
				<dd>收益</dd>
			</dl>
			<dl @click="toInvit">
				<dt>
					<img src="../../../assets/images/agent_inv_code.png" alt="邀請碼">
				</dt>
				<dd>邀請碼</dd>
			</dl>
		</div>
		<!-- kol -->
		<div v-else-if="type == '2'" class="kol-nav nav">
			<dl class="qjc-fts-26 qjc-texta-l">
				<dt>上月收益</dt>
				<dd>
					<span>{{ lastMonthProfit }}</span>港元
				</dd>
			</dl>
			<span @click="$router.push('/agent/earnings')" class="qjc-fts-30 qjc-c-primary">查看詳情</span>
		</div>
		<div v-else class="argan-nav nav">
			<dl @click="$router.push('/agent/earnings')">
				<dt>
					<img src="../../../assets/images/agent_index_earnings.png" alt="收益">
				</dt>
				<dd>收益</dd>
			</dl>
			<dl @click="toInvit">
				<dt>
					<img src="../../../assets/images/agent_inv_code.png" alt="邀請碼">
				</dt>
				<dd>邀請碼</dd>
			</dl>
		</div>
		
		<!-- 套餐剩余次数 -->
		<div v-if="type == '1'" class="combo-counts">
			<h3 class="title">
				<img src="../../../assets/images/agent_combo_counts.png" alt="icon">
				<span>套餐剩餘次數</span>
			</h3>
			
			<dl>
				<dt class="qjc-c-primary">
					<span class="count">{{ remain }}</span>次
				</dt>
				<dd>套餐剩餘次數</dd>
			</dl>
			
		</div>
		
		<!-- 疫境求真，前往首页 -->
		<div @click="$router.push('/')" class="banner">
			<img class="qjc-wid-100" src="../../../assets/images/agent_index_banner.png" alt="">
		</div>
		
		<!-- 套餐开通记录 -->
		<div v-if="type != '2'" class="history" :style="{ minHeight: kolCodeHeight + 'px' }" ref="kolCode">
			<h3 class="title">
				<img src="../../../assets/images/agent_open_history.png" alt="icon">
				<span>套餐銷售記錄</span>
			</h3>
			
			<van-list
				 v-model="getListing"
				 :finished="lastPage"
				 @load="getList"
				 ref="history"
			>
				<ul v-if="list.length > 0" class="list" ref="list">
					<transition-group name="van-slide-down">
						<li v-for="item in list" :key="item.id">
							<van-image
								width="0.72rem"
								height="0.72rem"
								:src="require('@/assets/images/agent_history_'+(item.sex==1?'nan':'nv')+'.png')"
							></van-image>
							<dl>
								<dt>{{ item.name }}</dt>
								<dd>{{ timestampTransform(item.created_at) }}</dd>
							</dl>
							<span class="sex">{{ item.sex==1?'男':'女' }}</span>
							<span class="birth">{{ timestampTransform(item.birth) }}</span>
							<span class="mobile qjc-relative">
								<span v-if="item.type == 3" class="no-count qjc-absolute">預購次數不足</span>
								<span v-if="item.type == 4" class="no-count qjc-absolute">禮品</span>
								{{ item.mobile }}
							</span>
						</li>
					</transition-group>
				</ul>
				
				<dl v-else class="no-history">
					<dt>
						<van-image
							width="1.44rem"
							height="1.44rem"
							:src="require('@/assets/images/agent_history_nan.png')"
						></van-image>
					</dt>
					<dd class="qjc-c-dark">暫無銷售記錄</dd>
				</dl>
			
			</van-list>
		</div>
		
		<!-- kol邀请码 -->
		<div v-if="type == '2'" :style="{ minHeight: kolCodeHeight + 'px' }" class="kol-code" ref="kolCode">
			<h3 class="qjc-fts-34 qjc-c-primary qjc-ftw-n qjc-texta-l">
				<img src="../../../assets/images/agent_index_kolcode.png" alt="">
				<span>邀请碼</span>
			</h3>
			
			<div id="code">
				<vue-qr 
					:logoSrc="require('@/assets/images/agent_miyinlogo.png')" 
					:text="inviteCodeUrl"
					:size="500"
					:margin="0"
					:logoScale=".25"
					:correctLevel="3"
				></vue-qr>
			</div>
			
			<div class="code-text qjc-fts-30">
				邀請碼：<span id="codeText">{{ inviteCode }}</span>
				<span @click="copy" data-clipboard-target="#codeText" class="copy qjc-fts-30 qjc-c-primary">複製</span>
			</div>
			
			<p class="qjc-fts-26">您的邀請碼可以邀請用戶成為您的代理人。</p>
			
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { DropdownMenu, DropdownItem, Image, Toast, List } from 'vant'
	
	Vue.use(DropdownMenu)
	   .use(DropdownItem)
	   .use(Image)
	   .use(Toast)
	   .use(List);
	
	import { agentInfo, openList, profit } from '@/js/axios'
	
	import vueQr from 'vue-qr'
	import Clipboard from 'clipboard';
	
	export default{
		name: 'agent',
		data() {
			return {
				minHeight: window.innerHeight,// 最低高度为全屏高度
				
				remain: 0,// 剩余次数
				
				getType: false,// 是否已获取用户类型
				
				// 定时器
				remainTimes: '',
				cmEarningsTimes: '',
				subordinateTimes: '',
				cmtTimesTimes: '',
				
				list: [],// 开通记录
				
				pager: {
					page: 1,// 默认第一页
					pagesize: 5,// 每页5条
				},
				getListing: false,// 获取列表中
				lastPage: false,// 是否最后一页
				
				// 邀请码
				inviteCode: '',
				sex: '',
				name: '',
				
				// 用户类型
				type: '',// 1.代理人 2.KOL 3.企业 4.员工
				
				inviteCodeUrl: '',
				kolCodeHeight: 0,
				
				lastMonthProfit: 0// kol独有上月收益
				
			}
		},
		components: {
			vueQr
		},
		mounted() {
			agentInfo().then(res => {
				if(res.code == 200){
					// 存储用户信息
					var userInfo = res.data; 
			
					// 自增到对应次数
					// 剩余次数
					this.autoNumber('remain', userInfo.leftTimes);
					
					// 邀请码内容
					this.inviteCode = userInfo.inviteCode;
					this.sex = userInfo.sex;
					this.name = userInfo.name;
					
					this.type = userInfo.type;// 获取type
					
					this.getType = true;
					if(this.type == '2'){// KOL
						profit({
							month: name
						}).then(res => {
							console.log(res)
							if(res.code == 200){
								var data = res.data;
								
								this.lastMonthProfit = data.lastMonthProfit;
								
							}else{
								Toast.fail(res.msg);
							}
						});
						
						this.inviteCodeUrl = location.origin + '/' + this.$router.resolve({
							path: '/agent/login',
							query: {
								invitCode: this.inviteCode
							}
						}).href;
					}else{
						this.$refs.history.check();
					}
					
					this.$nextTick(() => {
						// 内容最小一屏
						this.kolCodeHeight = this.minHeight - this.$refs.kolCode.offsetTop - (document.querySelector('html').style.fontSize.replace('px','')*0.24);
						
					})
			
				}else{
					Toast.fail(res.msg);
				}
			});
			
		},
		methods: {
			// 退出
			exit() {
				this.$store.dispatch('agentExit').then(() => {
					Toast.success('已退出');
					this.$router.push('/agent/login');
				});
			},
			
			// 获取套餐开通记录
			getList() {
				openList(this.pager).then(res => {
					console.log(res);
					if(res.code == 200){
						this.list = this.list.concat(res.data.data);
						this.pager.page++;
						
						// 是否为最后一页
						if(res.data.pager.page == res.data.pager.pagecount){
							this.lastPage = true;
						}
					}else{
						this.lastPage = true;
						Toast.fail(res.msg);
					}
					this.getListing = false;
				});
				
			},
			
			// 跳转至邀请码
			toInvit() {
				if(this.inviteCode){
					this.$router.push('/agent/invitation');
				}else{
					Toast.fail({
						message: '您沒有購買疫境求真套餐，邀請碼功能暫時無法使用',
						getContainer: '.agent'
					});
				}
			},
			
			// 自增数字到对应次数 参数：addNum:自增data字段。 maxNum:最大值(接口返回值) 
			autoNumber(addNum, maxNum) {
				// 触发前先停止定时器(防止定时器过程中再次触发)
				clearInterval(this[addNum+'Times']);
				
				var diff = maxNum - this[addNum];
				this[addNum+'Times'] = setInterval(() => {
					if((diff >= 0 && this[addNum] >= maxNum) || (diff < 0 && this[addNum] < maxNum)){
						this[addNum] = maxNum;
						clearInterval(this[addNum+'Times']);
					}else{
						if(diff >= 1000 || diff <= -1000){
							this[addNum] += Math.floor(maxNum/1000);
						}else{
							if(diff >= 0){
								this[addNum]++;
							}else{
								this[addNum]--;
							}
						}
					}
				}, (diff>=100 || diff <=-100)?1:30); 
			},
			
			// 时间戳转字符串  参数：时间戳(单位：s)
			timestampTransform(timestamp) {
				var time = new Date(timestamp * 1000),
					y = time.getFullYear(),
					m = time.getMonth() + 1,
					d = time.getDate();
				
				return y + '/' + (m<10?('0'+m):m) + '/' + (d<10?('0'+d):d);
			},
			
			copy() {
			  var clipboard = new Clipboard('.copy');
				clipboard.on('success', e => {  
					Toast.success("複製成功");
					// 释放内存  
					clipboard.destroy()  
				})  
				clipboard.on('error', e => {  
				  // 不支持复制  
				  Toast.fail('該瀏覽器不支持自動複製')  
				  // 释放内存  
				  clipboard.destroy()  
				})  
			}
			
		}
	}
</script>

<style scoped>
	.earnings >>> .van-dropdown-item{
		width: 2rem;
		left: auto;
		right: 0.24rem;
	}
	.earnings >>> .van-cell{
		padding: 0.16rem 0.24rem;
	}
	.earnings >>> .van-ellipsis{
		overflow: visible;
	}
	.earnings >>> .van-dropdown-menu__title,
	.earnings >>> .van-cell{
		font-size: 0.3rem;
	}
	.earnings >>> .van-dropdown-menu__title{
		padding-right: 0.24rem;
	}
	.earnings >>> .van-dropdown-menu__title::after{
		border-width: 0.09rem;
		border-color: transparent transparent #6681FA #6681FA;
		margin-top: -0.14rem;
		transition: all .2s;
	}
	.earnings >>> .van-dropdown-menu__title--down::after{
		margin-top: -0.04rem;
	}
	.months >>> .van-dropdown-item__content{
		border-radius: 0.06rem;
		border: 0.01rem solid #ddd;
	}
	.agent >>> .van-toast{
		width: auto;
		text-align: left;
	}
</style>
<style lang="scss" scoped>
	.agent{
		background-color: #EBEDF5;
		color: #3B4549;
		padding-bottom: 0.24rem;
		
		// 通用
		&>div{
			background-color: #fff;
			border-radius: 0.12rem;
			margin: 0 0.24rem 0.24rem;
			padding: 0.28rem 0.24rem;
			
			&:last-child{
				margin-bottom: 0;
			}
			
			.title{
				font-weight: 400;
				font-size: 0.34rem;
				line-height: 0.44rem;
				text-align: left;
				
				span,
				img{
					vertical-align: middle;
				}
				img{
					width: 0.44rem;
					height: 0.44rem;
					margin-right: 0.12rem;
				}
			}
		}
		
		.header{
			height: 2.06rem;
			line-height: 0.98rem;
			font-size: 0.34rem;
			color: #fff;
			font-weight: 500;
			border-radius: 0 0 0.12rem 0.12rem;
			background-color: #6883FB;
			margin: 0;
			padding: 0;
			z-index: 1;
			
			.exit{
				top: 0;
				right: 0.24rem;
				font-size: 0.28rem;
				font-weight: 400;
			}
			
			&.kol-bg{
				background: url(../../../assets/images/agent_invitation_headbg.png) no-repeat;
				background-size: 100% 100%;
			}
			
			.oper{
				top: 0.28rem;
				right: 0.24rem;
				line-height: 1;
				
				img{
					width: 0.44rem;
					height: 0.44rem;
					margin-left: 0.24rem;
				}
			}
		}
		
		.nav{
			position: relative;
			display: flex;
			justify-content: space-between;
			padding: 0.4rem 0.69rem 0.36rem;
			margin-top: -1.08rem;
			z-index: 10;
			
			img{
				width: 0.96rem;
				height: 0.96rem;
			}
			dd{
				font-size: 0.26rem;
				line-height: 0.26rem;
				margin-top: 0.12rem;
			}
			
			// kol
			&.kol-nav{
				align-items: center;
				padding: 0.55rem 0.36rem;
				
				dl{
					dt{
						line-height: 0.37rem;
					}
					dd{
						margin-top: 0.04rem;
						
						span{
							font-size: 0.64rem;
							line-height: 0.64rem;
							font-weight: 500;
						}
					}
				}
				&>span{
					position: relative;
					padding-right: 0.24rem;
					
					&::after{
						content: '';
						position: absolute;
						top: 50%;
						right: 0;
						width: 0.16rem;
						height: 0.16rem;
						border-top: 0.01rem solid #6883FB;
						border-right: 0.01rem solid #6883FB;
						transform: translateY(-50%)rotateZ(45deg);
					}
				}
			}
		
			// 机构和员工
			&.argan-nav{
				background-color: transparent;
				padding: 0;
				
				dl{
					width: 3.39rem;
					height: 2.16rem;
					background-color: #fff;
					border-radius: 0.12rem;
					padding-top: 0.4rem;
				}
			}
			
		}
		
		.banner{
			padding: 0;
			
			img{
				display: block;
				height: auto;
			}
		}
		
		.combo-counts,
		.earnings{
			dl{
				font-size: 0.26rem;
				line-height: 0.26rem;
				margin: 0.3rem 0 0.16rem;
				
				dt{
					span{
						font-size: 0.56rem;
						line-height: 0.56rem;
					}
				}
				dd{
					color: #8C9699;
					margin-top: 0.08rem;
				}
			}
		}
		
		.earnings{
			.months{
				position: relative;
				min-width: 1rem;
				height: 0.44rem;
				line-height: 0.44rem;
				
				&::after{
					border: none;
				}
			}
			
			.qjc-clearfix{
				dl.qjc-fl{
					border-right: 0.01rem solid #8C9699;
				}
				
				dt{
					span{
						font-size: 0.4rem;
					}
				}
			}
		}
		
		.history{
			font-size: 0.27rem;
			line-height: 0.27rem;
			padding-right: 0;
			
			.list{
				color: #3D404D;
				text-align: left;
				margin-top: 0.5rem;
				padding-right: 0.24rem;
				overflow-y: auto;
				
				li{
					display: flex;
					justify-content: space-between;
					
					&:not(:last-child){
						margin-bottom: 0.4rem;
					}
					
					dl,
					span{
						padding-top: 0.04rem;
					}
					
					dd{
						color: #8C9699;
						font-size: 0.22rem;
						line-height: 0.22rem;
						margin-top: 0.13rem;
					}
					
					dl{
						flex: 0.4;
						margin-left: 0.12rem;
					}
					.sex{
						flex: .25;
					}
					.birth{
						flex: .7;
					}
				}
				.no-count{
					top: 0;
					right: 0;
					padding: 0.08rem 0.16rem;
					font-size: 0.26rem;
					font-weight: normal;
					color: #B6BCBD;
					border: 0.01rem solid #B6BCBD;
					border-radius: 0.04rem;
					white-space: nowrap;
					transform-origin: right center;
					transform: rotateZ(-10deg);
				}
			}
		
			.no-history{
				padding: 1.28rem 0;
				
				dd{
					margin-top: 0.24rem;
				}
			}
		}
		
		.kol-code{
			padding: 0.36rem;
			
			h3{
				display: flex;
				align-items: center;
				border-bottom: 0.01rem solid #E1E3EB;
				line-height: 0.48rem;
				padding-bottom: 0.27rem;
				
				img{
					width: 0.48rem;
					height: 0.48rem;
					margin-right: 0.04rem;
				}
			}
			
			#code{
				width: 5.66rem;
				height: 5.66rem;
				margin: 0.52rem auto 0.36rem;
				
				img{
					width: 100%;
					height: 100%;
				}
			}
			
			.code-text{
				display: inline-block;
				line-height: 0.56rem;
				background-color: #DADFF5;
				border-radius: 0.28rem;
				margin: 0 auto;
				padding: 0 0.32rem;
				
				.copy{
					margin-left: 0.24rem;
				}
			}
			
			p{
				color: #8C9699;
				line-height: 0.34rem;
				margin-top: 0.16rem;
			}
			
		}
		
	}
</style>
